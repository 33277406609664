import { Select } from '@shopify/polaris'

function ChangeList({ contentTest, urlPath }) {
  const options = contentTest.changes.sort((test_a, test_b) => test_b.urlPath.localeCompare(test_a.urlPath)).map(change => show(change))

  return (
    <div className="ChangeList Item">
      <Select
        labelInline
        options={options}
      />
    </div>
  )
}

function show(change) {
  return {
    label: `${path()} <${change.newStyle.tagName}>  ${text()}`
  }

  function path() {
    return change.urlPath === '/' ? 'home' : change.urlPath
  }
  function text() {
    if (!change.initialStyle.text) return ''
    return  `=> ${change.initialStyle.text}`
  }
}

export default ChangeList
