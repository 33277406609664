import { Banner, Button, Card, DataTable, Icon, Link, Pagination, Spinner, Stack } from '@shopify/polaris'
import { CircleAlertMajor } from '@shopify/polaris-icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import StatusTestAb from '../../components/StatusTestAb/StatusTestAb'
import useCustomHistory from '../../hooks/useCustomHistory'
import { useTestsAB } from './hooks'
import './style.scss'
import moment from 'moment'
import ItemMenu from './components/ItemMenu/ItemMenu'
import DashboardFilter from './components/DashboardFilter/DashboardFilter'
import BulkProductListTooltip from '../../components/Fields/BulkProductListTooltip'
import DashboardHelper from '../../lib/DashboardHelper'
import NoTestsLink from './components/NoTestsLink/NoTestsLink'
import { InfoMinor } from '@shopify/polaris-icons'
import ContentItemMenu from './components/ContentItemMenu/ContentItemMenu'
import UpsellModal from '../../components/Upsell/UpsellModal'
import { useChangePlan } from '../Pricing/hooks'
import PauseFinishModal from './components/PauseFinishModal/PauseFinishModal'
import { useNavigate } from '@shopify/app-bridge-react'

const getImageProduct = (test) => {

  if (test.parent_on_bulk) {
    if (test.childrenTests[0]) {
      if (test.childrenTests[0].sample_image || null) {
        return test.childrenTests[0].sample_image
      } else {
        return '/logo192.png'
      }
    }
  }
  if (test.test_a) {
    if (test.test_a.images) {
      if (test.test_a.images.length) {
        return test.test_a.images[0].s3_src || test.test_a.images[0].src
      }
    }
  }

  return '/logo192.png'
}

function formatTimestamp(timestamp) {
  const date = new Date(timestamp)
  const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  return formattedDate
}

function Dashboard({ shop }) {
  const [page, setPage] = useState(1)
  const history = useCustomHistory()
  const [{ total, list, searchParam, statusParam, sortParams, loaded, data, appExtension, showUpsell }, { getTests, setSearchParam, setStatusParam, deleteTest, setSortParams, setShowUpsell, winnerTest, pauseTest, resumeTest, resumeTestWithPlanCheck, resumeBulkTestWithPlanCheck, resumeBulkTest, deleteBulkTest, deleteContentTest, changeContentTestStatus, pauseBulkTest, finishBulkTest }] = useTestsAB()

  const navigate = useNavigate()

  const [changePlan] = useChangePlan()

  const dispatch = useDispatch()

  const columns = ['name', 'product_title', 'status', 'createdAt', 'time', 'type']

  useEffect(() => {
    getTests(page, '')
    dispatch({
      type: 'SET_TEST_AB', data: {
        start_date: moment().toDate(),
        finish_date: moment().add(14, 'days').toDate(),
        variant_every: 30,
        variant_every_type: 'minutes',
        automatically_set_winner: false,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  function bulkOrProductTitle(test) {

    if (isBulkParent(test)) {
      return (<div style={{ display: 'flex', gap: '5px' }}> {test.childrenTests.length} Products <BulkProductListTooltip testAB={test} />
      </div>)
    }
    if (isProductAttribute(test)) return test.test_a.title.length > 20 ? `${test.test_a.title.substring(0, 20)}...` : test.test_a.title
    if (isContentTest(test)) return 'Content Test'
  }

  const rows = list.map(test => (
    [
      <div className="productName">
        <div className="img"><img src={getImageProduct(test)} alt="" /></div> {test.name.length > 10 ? `${test.name.substring(0, 10)}...` : test.name}
      </div>,
      <div className="block">
        {bulkOrProductTitle(test)}
      </div>,
      <div className="block">
        <StatusTestAb test={test} />
      </div>,
      <div className="block">
        {formatTimestamp(test.createdAt)}
      </div>,
      <div className="block">
        {DashboardHelper.getTime(test.time, test.status)}
      </div>,
      <div className="block">
        {test.type}
      </div>,
      <div className="actions">
        {isContentTest(test) && <Link removeUnderline onClick={() => { history.push(`/content-test/${test._id}/editor`) }}>Editor</Link>}
        <Link removeUnderline onClick={() => { history.push(viewResultsUrl(test)) }}>View results</Link>
        {renderItemMenus(test)}
      </div>,
    ]
  ))

  const handleSort = useCallback(
    (index, direction) => {
      setSortParams({
        field: columns[index],
        order: (direction === 'descending' ? 'desc' : 'asc')
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows],
  )

  const resumeWithRedirect = (redirectTo, planName) => {
    if (redirectTo === 'upgrade') {
      changePlan(planName)
    } else {
      history.push('/pricing')
    }
  }

  return (
    <div className="Dashboard">
      <div style={{ marginBottom: '20px', textAlign: 'right' }}>
        <Button primary onClick={() => navigate('/welcome')}>Create Test</Button>
      </div>
      <UpsellModal active={showUpsell} setActive={setShowUpsell} action={resumeWithRedirect} />
      <FreeLimitaionWarning shop={shop} history={history} />
      <AppExtensionWarning appExtension={appExtension} shop={shop} />
      <Card>
        <Card.Section>
          <DashboardFilter
            status={statusParam}
            setStatus={setStatusParam}
            search={searchParam}
            setSearch={setSearchParam}
          />
        </Card.Section>
        <PauseFinishModal variant={'set_winner'} updateTest={winnerTest} hideAcivator />
        <PauseFinishModal variant={'pause'} updateTest={pauseTest} hideAcivator />
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'text',
            'text',
            'text',
            'text',
            'text',
          ]}
          headings={[
            'Name',
            'Product',
            'Status',
            'Created date',
            'Time',
            'Type',
            '',
          ]}
          rows={rows}
          sortable={[true, true, true, true, true, true, true]}
          defaultSortDirection={sortParams.order === 'desc' ? 'descending' : 'ascending'}
          sort
          onSort={handleSort}
        />
        {!loaded &&
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Spinner />
          </div>}
        <Stack distribution="center" >
          <Pagination
            label={`${page} of ${Math.ceil(total / 5)}`}
            hasPrevious={page !== 1}
            onPrevious={() => {
              setPage(page - 1)
              console.log('Previous')
            }}
            hasNext={page < Math.ceil(total / 5)}
            onNext={() => {
              setPage(page + 1)
              console.log('Next')
            }}
          />
        </Stack>
        <div style={{ height: '.5rem' }}></div>

      </Card>
      {shopHasNoTests() && <NoTestsLink rows={rows} />}
    </div>
  )

  function renderItemMenus(test) {
    if (test.parent_on_bulk) {
      return (
        <ItemMenu status={test.status} id={test._id} testAB={test} deleteTest={deleteBulkTest} resumeTest={resumeBulkTest} pauseBulkTest={pauseBulkTest} finishBulkTest={finishBulkTest} resumeTestWithPlanCheck={resumeBulkTestWithPlanCheck} />
      )
    } else if (test.testType === 'content') {
      return (
        <ContentItemMenu list={list} contentTest={test} deleteContentTest={deleteContentTest} changeContentTestStatus={changeContentTestStatus} />
      )
    }
    else {
      return (
        <ItemMenu status={test.status} id={test._id} testAB={test} deleteTest={deleteTest} resumeTest={resumeTest} resumeTestWithPlanCheck={resumeTestWithPlanCheck} />
      )
    }
  }

  function viewResultsUrl(test) {
    if (isBulkParent(test)) return `/bulk_details/${test._id}`
    if (isProductAttribute(test)) return `/details/${test._id}`
    if (isContentTest(test)) return `/content-test/${test._id}/results`
  }

  function shopHasNoTests() {
    return data.length === 0
  }

  function isBulkParent(test) {
    return test.testType === 'bulk_parent'
  }

  function isProductAttribute(test) {
    return test.testType === 'product_attribute'
  }

  function isContentTest(test) {
    return test.testType === 'content'
  }
}

function FreeLimitaionWarning({ shop, history }) {
  if (shop.pricingPlan === 'Free')
    return (
      <div className="FreeLimitaionWarning">
        <Banner
          status="info"
          action={{
            content: 'Upgrade to premium',
            onAction: () => { history.push('/pricing') },
            external: false
          }}
          title="Free plan limitations">
          You are currently on our Free plan and are only able to run a single test concurrently. Upgrade your TridentAB plan now to run more tests.
        </Banner>
      </div>
    )
  return <></>

}

function AppExtensionWarning({ appExtension, shop }) {
  if (!appExtension) return <></>
  if (appExtension.loaded) return <></>

  // if (true) return <></>

  return (
    <div className="AppExtensionWarning">
      <Banner
        icon={CircleAlertMajor}
        status="success"
        action={{
          content: 'Continue',
          url: `https://${shop.shopify_domain}/admin/themes/current/editor?context=apps&uuid=${appExtension.uuid}`,
          external: true
        }}
        secondaryAction={{
          content: <LearnMore />,
          url: 'https://tridentab.zendesk.com/hc/en-us/articles/14052047402263',
          external: true
        }}
        title="You need to enable our TridentAB App Extension">
        TridentAB requires an App Extension to be enabled on your Online Store in order to work correctly. Click the “Continue” button below and enable the TridentAB App Extension to continue.
      </Banner>
    </div>
  )
}

function LearnMore() {
  return (
    <div className="LearnMore">
      <span className="text">Learn more</span>
      <div className="tooltip"><Icon source={InfoMinor} color="base" />
        <span className="tooltiptext"><LearnMoreToolTip /></span>
      </div>
    </div>
  )
}

function LearnMoreToolTip() {
  return (
    <div className="LearnMoreToolTip">
      Need more guidance? <span className="guide-link" data-url="https://www.loom.com/share/490d26dfe17b4a25973dead4e2968c65">Click here</span> to see a <span className="guide-link" data-url="https://www.loom.com/share/490d26dfe17b4a25973dead4e2968c65">step-by-step video guide</span> on how to do this.
    </div>
  )
}

export default Dashboard
