import './style.scss'

function ContentContainer({ pageRef, contentTest, urlPath, onLoad }) {
  const id = 'content_test_editor_iframe'
  const title = 'Content Editor'

  if (!contentTest) return <></>

  return (
    <div className="ContentContainer">
      <iframe
        ref={pageRef}
        id={id}
        name={id}
        title={title}
        sandbox="allow-scripts allow-same-origin"
        src={`${contentTest.storeURLForIframe}?path_url=${urlPath}`}
        onLoad={onLoad}
      />
    </div>
  )
}

export default ContentContainer
