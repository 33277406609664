/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import useContentTest from './hooks'
import './style.scss'
import { MessageTypes, Role } from '../../lib/content-test/shared/Types'
import NavBar from './components/NavBar/NavBar'
import ContentContainer from './components/ContentContainer/ContentContainer'
import Form from './components/Form/Form'
import Interface from '../../lib/content-test/shared/Interface'
import { ModalProvider } from './contexts/ModalContext'
import ContentTestLoading from './components/Loading/Loading'
import { useAppBridge } from '@shopify/app-bridge-react'
import { Fullscreen } from '@shopify/app-bridge/actions'
import useCustomHistory from '../../hooks/useCustomHistory'

function ContentTestEditor({ shop }) {
  const params = useParams()
  const containerRef = useRef()
  const app = useAppBridge()
  const history = useCustomHistory()
  const fullscreen = Fullscreen.create(app)
  const [selectedMode, setSelectedMode] = useState(undefined)
  const [urlPath, setUrlPath] = useState('/')
  const [selectedElement, setSelectedElement] = useState(undefined)

  const changeElementInThePage = (newSelectedElement, keepElement) => {
    if (selectedElement && !newSelectedElement && !keepElement) {
      sendElementChangeToThePage({ ...selectedElement, newStyle: selectedElement.initialStyle })
    }
    setSelectedElement(newSelectedElement)
  }

  const sendModeChangeToThePage = () => sendMessage({ messageType: MessageTypes.Editor.ModeChanged, data: { selectedMode } })
  const loadTestIntoPage = () => sendMessage({ messageType: MessageTypes.Editor.EditorOpened, data: { contentTest, urlPath } })
  const sendElementChangeToThePage = (selectedElement) => selectedElement && sendMessage({ messageType: MessageTypes.Editor.ElementChanged, data: { elementChange: selectedElement } })

  useEffect(sendModeChangeToThePage, [selectedMode])
  useEffect(loadTestIntoPage, [urlPath])
  useEffect(() => sendElementChangeToThePage(selectedElement), [selectedElement])

  const { contentTest, save, dispatch } = useContentTest(params.id)

  const sendMessage = ({ messageType, data }) => {
    const interface_ = Interface.build(Role.Editor, containerRef.current.contentWindow)
    interface_.subscribeToMessages({
      messageMapping: {
        [MessageTypes.Page.ElementSelected]:({ data }) => setSelectedElement(data.elementChange),
        [MessageTypes.Page.UrlPathChanged]: ({ data }) => setUrlPath(data.path)
      }
    })
    interface_.send({ messageType, data })
  }

  if (!contentTest) return <>Loading...</>

  const onLoad = () => {
    loadTestIntoPage()
    setTimeout(() => {
      setSelectedMode('edit')
    }, 5000) // To Do: Fix
  }

  const onSave = () => {
    save()
    fullscreen.dispatch(Fullscreen.Action.EXIT)
    return history.push('/')
  }

  return (
    <div className="ContentTestEditor">
      <NavBar contentTest={contentTest} onSave={onSave} selectedMode={selectedMode} setSelectedMode={setSelectedMode} urlPath={urlPath} setUrlPath={setUrlPath} shop={shop} />
      <ContentTestLoading selectedMode={selectedMode} />
      <ContentContainer pageRef={containerRef} contentTest={contentTest} urlPath={urlPath} onLoad={onLoad} />
      <ModalProvider>
        <Form selectedElement={selectedElement} setSelectedElement={setSelectedElement} contentTest={contentTest} changeElementInThePage={changeElementInThePage} urlPath={urlPath} dispatch={dispatch} />
      </ModalProvider>
    </div>
  )

}

export default ContentTestEditor
